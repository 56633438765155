@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiAlert-filledSuccess {
  background: #00843d !important;
}

.swal2-container {
  z-index: 999999 !important;
}
